/* style.css*/
@keyframes zoomIn {
  from {
    opacity: 0;
    /* transform: scale3d(.3, .3, .3); */
  }

  30% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  30% {
    opacity: 0;
    /* transform: scale3d(.3, .3, .3); */
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

.animate{
  animation-duration: 400ms;
}