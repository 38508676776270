

.spd-mt-10{
  margin-top: 10px;
}
.spd-mt-20{
  margin-top: 20px;
}
.spd-mt-30{
  margin-top: 30px;
}
.spd-mb-10{
  margin-bottom: 10px;
}
.spd-mb-20{
  margin-bottom: 20px;
}
.spd-mb-30{
  margin-bottom: 30px;
}

.spd-mr-10{
  margin-right: 10px;
}

.spd-ml-10{
  margin-left: 10px;
}

.form-control:focus {
    box-shadow: none;
}
.btn:focus{
  box-shadow: none;
}


.text-white{
  color: #fff;
}

.btn{
  cursor: pointer;
}

.bg-pink{
  background-color: #CD1A57;
}

.bg-pink-light{
  background-color:#DD1C5E ;
}

.bg-cyan{
  background-color: #00a5b7;
  
}

.bg-cyan-light{
  background-color: #00BCD4;
}

.bg-green{
  background-color:#7AAB41;
  
}

.bg-green-light{
  background-color: #84B946;
}

.bg-orange{
  background-color:#E08600;
  
}

.bg-orange-light{
  background-color: #F29000;
}

/*************** fabgear css start  **************/
  .addorder .form-group {
      
      padding: 0px 10px;
  }
  .addorder .form-group label{
    margin-bottom: 0px;
  }
  .addorder .addproduct .table{
      padding-top: 30px;
  }
  .addorder .modal-header{
    border-bottom: none;
  }
  .addorder .table td, .addorder .table th{
    border-top: none;
  }
  .addorder .modal-body .form-group {
    width: 100%;
    float: none;
    padding: 0px 0px;
}
.addorder .total-right{
  float: right !important;
}
.addorder .btn-clear{
  margin-right: 10px;
}

.addorder  .dropdown{
  display: inline;
}
@media (min-width: 576px){
  .addorder .modal-dialog {
      max-width: 1000px;
  }
}

.addproduct{
  margin-top: 30px;
}

.myorderDetails .addproduct{
  margin-top: 0px;
  width: 100%;
}

.add-product .select-model{
  margin-top: 20px;
  margin-bottom: 20px;
}

.add-product .select-model .dropdown {
 display: inline-block;
}

.add-product .select-model input{
    width: 90px;
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 6px;
    padding-bottom: 8px;
    margin-right: 10px;
    margin-left: 10px;
    text-align: center;
}

.add-product .selected-model{
  margin-left: 2px;
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 2px;
  margin-bottom: 10px;
}

.add-product .selected-model  span{
  color: #000;
  border-radius: 5px;
  padding-right: 0px;
  padding-left: 0px;
  cursor: pointer;
}

.add-product .added-Model .table-responsive{
 display: flex;
}

.add-product .added-Model .table-responsive td{
  border-top: 0px;
}
.add-product-row{
  cursor: pointer;
}
.add-product-row:hover{
  background-color: rgba(0,0,0,.05);
}
.productlist .product{
  text-align: center;
  position: relative;
  border: 1px solid #eee;
}
.productlist .product .product-content {
    padding: 10px 0px;
}
.productlist .product img{
  width: 100%;
  height: auto;
}
.productlist .product a.title {
    color: #000;
    text-decoration: none;
}
.productlist .product .money {
    color: #333;
    font-weight: 700;
    margin-bottom: 0px;
}
.productlist .banner-img{
  width: 100%;
}
.productlist a.full-anchor {
    text-decoration: none;
}
.productlist .cross-tk {
    color: #ddd;
    margin-left: 10px;
    text-decoration: line-through;
}
.productlist .product .title {
    font-weight: 600;
    color: #434343;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
}
.productlist .product .product-content .btn-add-cart{
  text-transform: uppercase;
  background-color: #e7e7e7;
  color: #555;

}
.productlist .product .product-content .btn{
  font-size:14px;
  font-weight: 600;
}
.productlist .product .product-content .btn-add-cart:hover {
    background-color: #47a9d8;
    color: #fff;
    transition: all 0.2s linear;
}
.productlist .product .post-tag {
    position: absolute;
    top: 10px;
    left: 20px;
    height: 40px;
    width: 40px;
    background-color: #F77676;
    line-height: 40px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    border-radius: 50%;
}
.product-sidebar .card {
    box-shadow: none;
}
.product-sidebar .card .card-header {
    padding: .75rem 0rem;
    border-bottom: 1px solid #ddd;
}
.product-sidebar .card .card-header a.card-title {
    padding: 0rem;
    font-weight: 800;
    text-transform: uppercase;
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
.product-sidebar .card-block {
    padding: 0rem;
}
.accordion .card-header:after {
    font-family: 'FontAwesome';  
    content: "\f068";
    float: right; 
    border: 1px solid #45a6e6;
    color: #45a6e6;
    height: 20px;
    width: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}
.accordion .card-header.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\f067"; 
}
.card-block ul.list {
    padding-left: 0px;
    margin-left: 0px;
}

.product-sidebar .card-block ul.list li {
  list-style: none;
  border-bottom: 1px dotted #ddd;
  padding: 10px 0px;
}
.product-sidebar .card-block ul.list li:last-child {
  border-bottom: none;
}
.product-sidebar .card-block ul.list li label {
    color: #434343;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 0px;
}
.product-sidebar .card-block ul.list li input[type="checkbox"] {
    margin-right: 5px;
}

.DayPickerInput input{
    background: transparent;
    border: 1px solid grey;
    padding: 5px;
    border-radius: 5px;
    margin-left: 10px;
    text-align: center;
}

.order-payment input, .order-payment select, .order-payment span{
    width: 100%;
}

.order-payment input{
  border: 1px solid gray;
  border-radius: 5px;
  padding: 6px;
}

.order-payment select{
    padding: 9px;
    border-radius: 5px;
}

.payment-table{
  margin-top: 15px;
}

.cus-address{
  margin-top: 57px;
}

.react-datepicker__input-container input{
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #ddd;
}

/* customer section inn add order */
.customer-section, .addproduct, .payment-section{
  padding: 25px;
  /* border-radius: 5px; */
  margin-bottom: 50px;
  border: 1px solid #ddd;
  background-color: #fff;
}

p#grand-total, #due {
    /* padding-left: 12px; */
    background: #33b35a;
    padding: 8px;
    color: #fff;
    font-size: -5px;
    border-radius: 3px;
    text-align: center;
}

#due{
  background: #dc3545;
}

.space-lbl {
    padding-right: 15px;
}

#open-btn{
  color: #ddd;
  display: none;
}

#cls-btn{
  color: #ddd;
  display: none;
  text-align: right;
  background-color: #292929;
  position: absolute;
  left: 140px;
}

.add-product{
  margin-top: 50px;
}

.products{
  margin-top: 50px;
}
.products .searchtext{
  margin-bottom: 20px;
}
.customer .searchtext{
  margin-bottom: 20px;
}

.img-productspage{
  width: 100px;
}
/* invoice style start */
/* .logo{
  float: none;
  width: 20%;
} */
.invoice{
    color: #000 !important;
}

.invoice table{
  color: #000 !important;
}

.invoice .logo{
  width: 100%;
}

.right-invoice{
  float: right;
}
.right-invoice .invoice {
    color: #000;
    font-size: 25px;
    font-weight: 700;
}
.right-invoice .taka {
    color: #000;
    font-size: 16px;
    font-weight: 400;
}
span.name {
    color: #000;
    font-weight: 600;
}
span.addres {
    text-decoration: underline;
}
.personal-detail {
    margin-top: 30px;
    margin-bottom: 30px;
}
thead.thead-dark {
    background-color: #eee;
}
.bdr-bottom {
    border-bottom: 1px solid #000;
}
tr.bg-tr {
    background-color: #eee;
    font-weight: 700;
}
tr.tr-fnt{
  font-weight: 600;
}

.invoice-notes{
    margin-top: 20px;

}
.invoice-notes h5{
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #ddd;
  font-weight: 600;
  width: 300px;
  margin-bottom: 0px;
}
.invoice-notes p{
  width: 500px;
  margin-top: 0px;
  font-weight: 600;
  padding: 10px;
  
}

.fab-addres address{
  display: inline-block;
}

.right-invoice .react-datepicker__input-container input{
  border: none;
  margin-left: -6px;
}

.invoicePaid{
    display: block;
    position: absolute;
    top: 180px;
    left: 600px;
    z-index: 1000;
   /*  height: 150px;
    width: 150px; */
}

.invoiceDue{
  display: none;
}
@media print {
  .invoicePaid{
    left: 400px;
    top: 150px;
  }
}
/* invoice style ended */

.imagename-list{
  border: 1px solid #000;
  border-radius: 10px;
  min-height: 80px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 5px 0px;
}

.dlt-btn{
  position: absolute;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  cursor: pointer;
}

.min-width-80{
  min-width: 80px;
}

@keyframes spinner-border {
  to { transform: rotate(360deg); }
} 
.spinner-border{
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
.spinner-border-sm{
    height: 1rem;
    border-width: .2em;
}

.dropdown-menu{
  max-height: 200px;
  overflow-y: scroll;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #008f68;
  color: #fae042;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

/* profile  page style */
.spd-min-height{
  min-height: 550px;
}

.profile button{
  margin-left: 10px;
}

.profile img{
  width: 100%;
}

/* .profile .profile-form{
  margin-top: 80px;
} */

/* profile page ended */


/* viewproduct start */


.image-section .main-image{
  width: 100%;
}

.image-section .bottom-image{
  margin-top: 20px;
}
.image-section .bottom-image img{
  width: 33.33%;
}

.details-section h1{
  font-size: 50px;
}

.details-section .orderQuantity, .cart .orderQuantity {
    width: 30px;
    height: 38px;
    margin: 0px;
    margin-left: -5px;
    padding-left:8px;
    padding-left:8px;
    /* height: 33px; */
    vertical-align: middle;
}

.details-section p.price, .details-section .select-model{
    font-size: 18px;
    margin-top: 30px;
}

.details-section p.price span{
    color: #ff82ff;
}

span.quantity-wapper {
    display: inline-flex;
    margin-left: 20px;
}

.models .dropdown button{
  background-color: #a46497;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
}
/* viewproduct ends */

/* cart start */
.cart{
  margin-top: 50px;
}

.cart table{
  width: 100%;
}

.cart img{
  height: 150px;
  width: 150px;
}

.cart table td, .cart table th {
  vertical-align: middle;
  text-align: center;
}

.cart .cart-title-price{
  padding-left: 10px;
  text-align: left;
}

/* .cart .cart-qty input{
  padding:  5px 5px 5px 10px;

  border: 1px solid #ddd;
  border-radius: 5px;
  width: 60px;
} */

.billing-information input , .billing-information textarea{
    margin-top: 10px
}

/* cart ends */


@media only screen and (max-width: 767px) {
  .cart img{
    height: 50px;
    width: 50px;
  } 

  .cart{
    font-size: 14px;
  }

  .cart-title-price h1{
    font-size: 14px;
  }

  .quantity-wapper i , .quantity-wapper input{
      width: 100%;
  }
}


.uploaded-image img{
  width: 100px;
}


/************ cc products css strat ****************/
.profile-author {
    position: relative;
    margin-bottom: 30px;
}
.profile-author img {
    width: 100%;
}
.profile-author .profile-icon {
    position: absolute;
    top: 0;
    right: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}
.profile-author .profile-icon .fa-times {
    background-color: red;
}
.profile-author .profile-icon .fa {
    height: 25px;
    width: 20px;
    text-align: center;
    line-height: 25px;
    color: #fff;
    cursor: pointer;
}
.modelshow {
    margin-left: 0px;
    display: inline-block;
}
.modelshow li {
    float: left;
    list-style: none;
    margin-right: 10px;
    background-color: green;
    color: #fff;
    margin-top: 10px;
    padding-left: 10px;
}

.sm-th{
 width: 100px;
}

.page-no{
  padding: 5px 10px;
  margin-right: 5px;
  color: #000;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-top: 10px;
  cursor: pointer;
}

.inline-form{
  border: 1px solid #ddd;
  color: #000;
  padding: 6px 10px;
  border-radius: 5px;
  margin-left: 10px;
}


#page-active{
  background-color: #393836;
  color: #fff;
}

.distribution-list{
  margin-bottom: 40px;
}
.distribution-list h3{
  background-color: #218838;
  color: #fff;
  padding: 10px 15px;
  font-size: 18px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.distribution-list .table{
  font-size: 12px;
  margin-top: 0px;
}

.product-tooltip{
  color: white;
  font-weight: 400;
  width: 100%;
  height: 100%;
  font-size: 14px;
  margin-bottom: 2px;
  padding: 3px;
  border: 1px solid #ddd;
}

.product-tooltip p{
   color: white;
  margin-bottom: 2px;
  padding: 0px;
}

.dashboard-wrapper{
  padding-left: 15px;
  padding-right: 15px;
}

.dashboard-block{
  color: #fff;
  padding: 20px 10px;
}

.dashboard-block p{
  color: #fff;
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-size: 12px;
}

.dashboard-block h1{
  font-size: 28px;
  
}

.dashboard-icon{
  color: #fff;
  padding-top: 20px;
  text-align: center;
}

.month-delivery{
  padding: 10px 20px;
  color: #fff;
  background-color: #F44336;
  font-size: 18px;
  margin-bottom: 0px;
}


.myorderDetails .form-control:disabled, .form-control[readonly]{
  background-color: transparent;
  border: 0.5px solid #ddd;
}

/* .header-drop-wrapper .dropdown-menu{
    z-index: 1000;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
} */

.header-drop-wrapper button{
  background-color: transparent;
  color: #fff;
  font-size: 13px;
}

.recover-password{
  margin: 0 auto;
}

.hidden{
  display: none;
}


/* header hover dropdown */
.nav-dropdown {
    float: left;
    overflow: hidden;
}

.nav-dropdown .dropbtn {
    font-size: 13px;    
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
}
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1000;
}

.dropdown-content a {
    float: none;
    color: #000 !important;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {
    background-color: #393836;
    color: #fff !important;
}

.nav-dropdown:hover .dropdown-content {
    display: block;
}

.rds-btn{
  padding-right: 30px !important;
  padding-left: 30px !important;
  border-radius: 50px;
}

/********* cart button css start  **********/
.cart-wrap {
    position: relative;
    margin-top: 50px;
}
.cart-wrap .carts {
    position: fixed;
    top: 95px;
    right: 3.5%;
    z-index: 999;
}
.cart-wrap .carts .cart-btn {
    background-color: #000;
    color: #fff;
    border-radius: 25px;
    padding: 15px 20px;
}
.cart-wrap .carts .cart-total {
    padding-left: 10px;
}
.cart-wrap .cart-count {
    position: fixed;
    top: 85px;
    right: 3%;
    z-index: 999;
    height: 30px;
    width: 30px;
    background: #eee;
    line-height: 30px;
    text-align: center;
    border-radius: 100%;
    font-weight: bold;
    z-index: 9999;
}
.cart-wrap .cartlist-wrap {
    position: fixed;
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    width: 700px;
    z-index: 9999;
    top: 73px;
    right: 0;
    display: none;
    height: 100%;
}
.cartlist-wrap .cart-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    align-items: center;
}
.cartlist-wrap .cart-header .total {
    color: #000;
    margin: 0px;
    font-weight: bold;
    font-size: 20px;
}
.cartlist-wrap .cart-header .whistlist{
  margin: 0px;
}
.cartlist-wrap .cart-header .whistlist span{
  font-size: 18px;
}

.cartlist-wrap .cart-header .whistlist .fa-heart-o {
    color: #000;
    padding-right: 10px;
}
.cartlist-wrap .cartlist-body {
    overflow-y: scroll;
    height: 80%;
}

.cartlist-wrap .cartlist-body .dropdown-menu{
  height: 250px;
  overflow-y: scroll;
}

.addproduct .model-select{
  height: 300px;
  overflow-y: scroll;
}

.cartlist-wrap .cartlist-body .row{
    margin: 10px;
    background-color: #fff;
    padding: 10px 0px;
    display: flex;
    align-items: center;
}

.cartlist-wrap .cartlist-body .banner-img{
    width: 100%;
}

.cartlist-wrap .cartlist-body .model-title {
    margin: 0px;
    font-size: 20px;
    color: #000;
    text-transform: capitalize;
}
.cartlist-wrap .cartlist-body .price {
    margin: 0px;
  }
.cartlist-wrap .cartlist-body .quanty-txt{
  width: 55px;
  padding-left: 5px;
}
.cartlist-wrap .cartlist-body .btn-billing{
  background-color: #47a9d8;
  color: #fff;
  font-weight: 600;
  border-radius: 3px;
}

.cartlist-wrap .cartlist-body i.fa.fa-times.pull-right {
    cursor: pointer;
}

.cartlist-wrap .cart-header button {
    background-color: #000;
    border: none;
    color: #fff;
    border-radius: 3px;
    font-weight: 600;
}

.cartlist-wrap .cartlist-footer {
    width: 100%;
    text-align: center;
}

.cartlist-wrap .cartlist-footer span {
    background-color: #000;
    color: #fff;
    padding: 10px 35px;
    border-radius: 25px;
    margin: 20px;
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
}

#checkout-form{
  display: none;
}

#back-btn{
  display: none;
}

.searched-tag-warp .searched-tag{
  margin-top: 30px;
  background-color: #ddd;
  color: #000;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 5px;
}

#hiddenConPass{
  display: none;
}

#coupon-head{
  text-align: center;
  font-size: 12px;
  color: #666361;
  background-color: #ddd;
  cursor: pointer;
  margin-bottom: 0px;
}

#coupon-section{
  display: none;
}

.coupon-section{
  padding-top:  0px !important;
  text-align: center;
  font-size: 12px;
  color: #666361 !important;
  background-color: #ddd !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

/* fotter css start */
.footer-distributed{
  background-color: #292c2f;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 16px sans-serif;
  padding: 55px 50px;
  margin-top: 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
  display: inline-block;
  vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left{
  width: 40%;
}

/* The company logo */

.footer-distributed h3{
  color:  #ffffff;
  font: normal 36px 'Cookie', cursive;
  margin: 0;
}

.footer-distributed h3 span{
  color:  #5383d3;
}

/* Footer links */

.footer-distributed .footer-links{
  color:  #ffffff;
  margin: 20px 0 12px;
  padding: 0;
}

.footer-distributed .footer-links a{
  display:inline-block;
  line-height: 1.8;
  text-decoration: none;
  color:  inherit;
}

.footer-distributed .footer-company-name{
  color:  #8f9296;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center{
  width: 35%;
}

.footer-distributed .footer-center i{
  background-color:  #33383b;
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope{
  font-size: 17px;
  line-height: 38px;
}

.footer-distributed .footer-center p{
  display: inline-block;
  color: #ffffff;
  vertical-align: middle;
  margin:0;
}

.footer-distributed .footer-center p span{
  display:block;
  font-weight: normal;
  font-size:14px;
  line-height:2;
}

.footer-distributed .footer-center p a{
  color:  #5383d3;
  text-decoration: none;
}


.myorders-info-header{
  background-color: #2B954B;
  color: #fff;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 20px;
}


/* Footer Right */

.footer-distributed .footer-right{
  width: 20%;
}

.footer-distributed .footer-company-about{
  line-height: 20px;
  color:  #92999f;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer-distributed .footer-company-about span{
  display: block;
  color:  #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-distributed .footer-icons{
  margin-top: 25px;
}

.footer-distributed .footer-icons a{
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color:  #33383b;
  border-radius: 2px;

  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;

  margin-right: 3px;
  margin-bottom: 5px;
}


.log-comment{
  width: 500px !important;
}

/* footer  css ends */

/* notification css start */

.Toastify__close-button{
  display: flex;
  align-self: center;
}


.full-width{
  width:100%;
}



/* notification css end */

.select-date-range, .order-filter-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}



/********* cart button css end  **********/

@media screen and (max-width: 767px){

  .search-btn{
    width: 100%;
    margin-top: 20px;
  }

  .select-date-range, .order-filter-header{
    justify-content: center;
    padding: 15px;
  }

  .select-date-range{
    flex-direction: column;
  }

  .cartlist-wrap .cartlist-body .row{
    position: relative;
  }
  .cartlist-wrap .cartlist-body .top-right {
    position: absolute;
    top: 10px;
    right: 0px;
  }
  .cartlist-wrap .cartlist-body .top-right .fa-times {
    font-size: 20px;
    height: 30px;
    width: 30px;
    background-color: #000;
    color: #fff;
    line-height: 30px;
    text-align: center;
}
  .cartlist-wrap .cartlist-body .row .col-md-2{
    text-align: center;
  }
  .cartlist-wrap .cartlist-body .row .col-md-2 img{
    width: 150px;
    height: 100px;
  }
  .cart-wrap .cartlist-wrap{
    width: 100%;
    top: 0px;
  }
  .cartlist-wrap .cartlist-body .model-title {
    text-align: center;
    margin-top: 10px;
  }

  .cartlist-wrap .cartlist-body .price {
      text-align: center;
      margin-top: 10px;
    }
    .cartlist-wrap .cartlist-body .dropdown{
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  .cartlist-wrap .cartlist-body .quanty-txt{
    margin: 0 auto;
    
  }
  .cartlist-wrap .cartlist-body .cartlist-footer a {
    margin: 10px;
  }
  .details-section {
    margin-top: 30px;
  }
  nav.navbar a{
    margin-top: 6px;
  }

  /* nav.navbar {
    position: fixed;
    z-index: 999;
    top: 0;
    width: 100%;
  } */

  .cart-wrap{
      margin-top: 100px;
  }

  .customer-section, .addproduct, .payment-section {
    padding: 15px;
    margin-bottom: 20px;
  }
}
.viewproduct .title {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    
}
.viewproduct .span-price {
    color: #434343;
    font-weight: 600;
    font-size: 18px;
    padding-right: 10px;
}
.viewproduct .cross-tk {
    text-decoration: line-through;
    padding-left: 5px;
}
.viewproduct .dropdown button {
    background-color: #eee;
    color: #000;
    margin-top: 20px;
    margin-bottom: 20px;
}
.viewproduct .span-quantity{
  color: #434343;
  font-weight: 600;
  font-size: 18px;
}
.viewproduct .btn-plus {
    background-color: #eee;
    color: #000;
}
.viewproduct .btn-minus {
    background-color: #eee;
    color: #000;
}
.viewproduct .orderQuantity{
  margin-left: 0px;
}
.viewproduct .btn-add-cart:hover {
    background-color: #47a9d8;
    color: #fff;
}
.viewproduct .btn-add-wsihlist:hover {
    background-color: #bd2130;
    color: #fff;
}
.slide-small {
    text-align: center;
}
.slide-small img {
    height: 70px;
    width: 70px !important;
    margin: 0px 5px;
    border:2px solid transparent
}
.slide-small img:hover{
  border:2px solid #47a9d8
}
.viewproduct .all-material {
    background-color: #47a9d8;
    padding: 5px 10px;
    color: #fff;
    margin-right: 5px;
}
.viewproduct .outside {
    font-weight: bold;
}
.viewproduct .alice-carousel__wrapper{
  height: 100px;
}
.viewproduct .alice-carousel{
  padding: 0px;
}
.viewproduct .cart-icon .fa-shopping-cart {
    color: #47A9D8;
    font-size: 18px;
    padding-right: 5px;
}
.viewproduct .btn-add-cart:hover .cart-icon .fa-shopping-cart{
  color: #fff;
}
.wishlist .cart-wrap{
  margin-top: 0px;
}
/* carousel css */
#yours-custom-class{
  display: block;
  position: relative;
}

#yours-custom-class img{
  width: 100%;
}

.PrintBlankCase td{
  padding: 0px;
}

.status-indicator{
  border:2px solid #fff;
  height: 50px;
  width: 50px;

}

.multiselect-item{
  align-items:center; 
  display:flex; 
  flex-direction:row; 
  padding:5px 0px; 
  cursor:pointer;
  font-size: 12px;
}

.sidebar-toggle-btn{
  display: none;
}

.total-order-badge{
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  border:1px solid #ddd;
  margin:5px;
}

.modal-lg{
  max-width: 1200px;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  color: #000;
  font-weight: bold;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  border: 1px solid #ddd;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */


/* Style the accordion panel. Note: hidden by default */


.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  /* overflow: hidden; */
  transition: max-height 0.2s ease-out;
}

.categorySelectWrapeer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.categorySelectWrapeer .badge{
  margin: 2px;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
}


/* customizer css strt */

.draggableContentController{
  padding-top: 50px;
 position: relative;
 width: 40px;
 height: 100%;
 background-color: #fff;
}

.draggableContentControllerSm{
 position: absolute;
 display: flex;
 flex-direction: row;
 top: -30px;
 right: 0px;
}

.react-draggable button{
  height: 30px;
  width: 30px;
  color: red;
  background-color: #fff;
  font-size: 12px;
  display: none;
  opacity: 0.5;
  margin-left: 2px;
}

.react-draggable:hover .handle, .react-draggable:active .handle{
 border: 0.5px dashed rgba(255,255,255,0.5);
}

.react-draggable:hover button, .react-draggable:active button{
  display: block;
}


.border-list{
   padding-top: 10px;
   padding-bottom: 10px;
   border-bottom: 0.5px solid #ddd;
}

.border-list:last-child{
  border-bottom: 0px;
}

.orderHistory td{
  vertical-align: middle;
}

.rowALign{
  display: flex;
  align-items: center;
}

.contactUs input , .contactUs textarea{
  border-radius: 10px;
  border: 0.5px solid #ddd;
}

.floatingBtn{
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
 color: #0084ff;
 background-color: rgba(255,255,255,0.5);
 box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

}

#sticky-footer{
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.fontImageSelect{
 position:absolute; 
 width:200px; 
 cursor:pointer; 
 max-height:200px;
 border: #ddd 0.5px solid;
 border-radius: 10px;
 overflow-y: auto;
 z-index: 9999;
 padding: 20px;
 background-color: #fff;
}

.fontImageSelect img{
 width: 160px;
 margin: 5px 0px;
}

.roundBtn{
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  border:0px;
  background-color: transparent;
}

.roundBtn:hover{
  background-color:#eee;
  color: #d9121f;
}

.res-img{
  width:100%
}

button:focus, .searchSuggestion{
  box-shadow: none;
  outline: 0px;
}

.mock-img{
  /* z-index: 1; */
  
  background-size: contain;
  background-position: center;
 }

/* customizer css ends */

.icon-img{
  width: 20px;
  display: inline;
  margin-right: 5px;
}

.actionSelectBtn{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.hideScroll {
  -ms-overflow-style: none;
}

.modelSelectFromChat > div{
  /* padding-right: 2px;
  padding-left: 2px; */
  align-items: center;
  text-align: center;
  margin-bottom: 5px;
}

.modelSelectFromChat > div :first-child{
  /* padding-left: 15px; */
}

.ant-popover{
  z-index: 9999;
  max-height: 200px;
  overflow-y: auto;
  /* text-shadow: 2px 2px 4px #000000; */
}


.shadow{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.treeUl li{
  list-style: none;
  margin: 10px 0px;
}


/* media query for footer start */

@media (max-width: 768px) {
  .sidebar-toggle-btn{
    display: block;
  }

  .full-in-sm{
    width:100% !important;
  }

  .react-datepicker__input-container, .react-datepicker__input-container input, .react-datepicker-wrapper{
    width:100%;
  }

  .select-date-range > div{
    width: 100%;
  }
}

@media (max-width: 880px) {

  .footer-distributed{
    font: bold 14px sans-serif;
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right{
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .footer-distributed .footer-center i{
    margin-left: 0;
  }

}

@media (max-width: 360px) {
  #mainMsgFieldWrapper{
    width: 100px;
  }
}

/* media query for footer ends*/
/*************** fabgear css end  ****************/